import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './css/Settings.css'; 
import { UserContext } from '../MainPage';

const Settings = ({ onClose }) => {
  const [pageViewsData, setPageViewsData] = useState({});
  const [ws, setWs] = useState(null);
  const { user, rank } = useContext(UserContext);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false); // State for toggling the password form visibility
  const [showPageViews, setShowPageViews] = useState(false); // State for toggling the page views table visibility
  const [trackingEnabled, setTrackingEnabled] = useState(false); // State for tracking status

  // Neuer State für AGB-Bestätigungen
  const [agbConfirmations, setAgbConfirmations] = useState([]);
  const [showAgbTable, setShowAgbTable] = useState(false); // State for toggling the AGB table visibility

  useEffect(() => {
    const createWebSocket = () => {
      const socket = new WebSocket('wss://kidsauszeit.at:5000');

      socket.onopen = function() {
        socket.send(JSON.stringify({ action: "pageviews" }));
      };

      socket.onmessage = function (event) {
          const data = JSON.parse(event.data);
          switch (data.action) {
              case "pageViewsData":
                  setPageViewsData(data.data);
                  break;
              default:
                  break;
          }
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed. Reconnecting...');
        setTimeout(() => {
          createWebSocket(); 
        }, 2000);
      };

      socket.onerror = () => {
        console.error('WebSocket connection failed.');
      };

      setWs(socket);
    };

    createWebSocket();

    const checkTrackingStatus = () => {
      const deviceToken = localStorage.getItem('deviceToken') || document.cookie.split('; ').find(row => row.startsWith('deviceToken'))?.split('=')[1];
      return deviceToken === 'excludeDevice';
    };

    setTrackingEnabled(checkTrackingStatus());

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'oldPassword') {
      setOldPassword(value);
    } else if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
    validatePassword(name, value);
  };

  const validatePassword = (name, value) => {
    if (name === 'newPassword' || name === 'confirmPassword') {
      const requirementsRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?!.*\s).{8,}$/;

      if (!requirementsRegex.test(value)) {
        setPasswordError('Das Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl, einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthalten.');
      } else if (name === 'confirmPassword' && value !== newPassword) {
        setPasswordError('Das Passwort stimmt nicht überein.');
      } else {
        setPasswordError('');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setPasswordSuccess('');

    if (newPassword !== confirmPassword) {
      setPasswordError('Neues Passwort und Bestätigungspasswort stimmen nicht überein');
      return;
    }

    try {
      const response = await fetch('https://kidsauszeit.at:5000/changePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: user,
          oldPassword,
          newPassword
        })
      });

      if (!response.ok) {
        const data = await response.json();
        setPasswordError(data.error);
        return;
      }

      setPasswordSuccess('Password erfolgreich geändert');
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      setShowPasswordForm(false); // Hide the form after successful submission

    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordError('Beim Ändern des Passworts ist ein Fehler aufgetreten');
    }
  };

  const toggleShowPassword = (passwordType) => {
    switch (passwordType) {
      case 'old':
        setShowOldPassword(!showOldPassword);
        break;
      case 'new':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirm':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  // Abrufen der AGB-Bestätigungen vom Server
  const fetchAgbConfirmations = async () => {
    try {
      const response = await fetch('https://kidsauszeit.at:5000/getAGBConfirmations');
      const data = await response.json();
      setAgbConfirmations(data);
    } catch (error) {
      console.error('Fehler beim Abrufen der AGB-Bestätigungen:', error);
    }
  };

  const renderPageViewsTable = () => {
    if (!showPageViews) return null; // Hide the table if showPageViews is false

    const rows = [];
    let totalViews = 0;
  
    const sortedDates = Object.keys(pageViewsData).sort((a, b) => new Date(b) - new Date(a));
  
    sortedDates.forEach(date => {
      const views = pageViewsData[date];
      totalViews += views;
      rows.push(
        <tr key={date}>
          <td>{date}</td>
          <td>{views}</td>
        </tr>
      );
    });
  
    return (
      <div className="table-container">
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Aufrufe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>INSGESAMT</b></td>
              <td><b>{totalViews}</b></td>
            </tr>
            {rows}
          </tbody>
        </Table>
      </div>
    );
  };

  // Render-Methode für die AGB-Bestätigungen-Tabelle
  const renderAgbConfirmationsTable = () => {
    if (!showAgbTable) return null;

    return (
      <div className="table-container">
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>Vorname</th>
              <th>Nachname</th>
              <th>Email</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {agbConfirmations.map((confirmation, index) => (
              <tr key={index}>
                <td>{confirmation.vorname}</td>
                <td>{confirmation.nachname}</td>
                <td>{confirmation.email}</td>
                <td>{new Date(confirmation.timestamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderPWForm = () => {
    if (!showPasswordForm) return null; // Hide the form if showPasswordForm is false
 
    return (
      <Form onSubmit={handleSubmit}>
          <Form.Group controlId="oldPassword">
            <Form.Label>Altes Passwort</Form.Label>
            <div className="password-input-container">
              <Form.Control type={showOldPassword ? 'text' : 'password'} name="oldPassword" value={oldPassword} onChange={handleChange} autoComplete='current-password' />
              <FontAwesomeIcon icon={showOldPassword ? faEye : faEyeSlash} onClick={() => toggleShowPassword('old')} />
            </div>
          </Form.Group>
          <Form.Group controlId="newPassword">
            <Form.Label>Neues Passwort</Form.Label>
            <div className="password-input-container">
              <Form.Control type={showNewPassword ? 'text' : 'password'} name="newPassword" value={newPassword} onChange={handleChange} autoComplete='new-password' />
              <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} onClick={() => toggleShowPassword('new')} />
            </div>
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Label>Neues Passwort Bestätigen</Form.Label>
            <div className="password-input-container">
              <Form.Control type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" value={confirmPassword} onChange={handleChange} autoComplete='new-password' />
              <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} onClick={() => toggleShowPassword('confirm')} />
            </div>
          </Form.Group>
          {passwordError && <div className="error-message">{passwordError}</div>}
          {passwordSuccess && <div className="success-message">{passwordSuccess}</div>}
          <Button variant="primary" type="submit">Change Password</Button>
        </Form>
    );
  };

  const handleClose = () => {
    if (ws) {
      ws.close();
    }
    onClose();
  };

  const handleTogglePasswordForm = () => {
    setShowPasswordForm(prevShowPasswordForm => {
      const newShowPasswordForm = !prevShowPasswordForm;
      if (newShowPasswordForm) setShowPageViews(false);
      if (newShowPasswordForm) setShowAgbTable(false);
      return newShowPasswordForm;
    });
  };

  const handleTogglePageViews = () => {
    setShowPageViews(prevShowPageViews => {
      const newShowPageViews = !prevShowPageViews;
      if (newShowPageViews) setShowPasswordForm(false);
      if (newShowPageViews) setShowAgbTable(false);
      return newShowPageViews;
    });
  };

  const handleToggleAgbTable = () => {
    setShowAgbTable(prevShowAgbTable => {
      const newShowAgbTable = !prevShowAgbTable;
      if (newShowAgbTable) {
        fetchAgbConfirmations(); // AGB-Bestätigungen nur abrufen, wenn die Tabelle angezeigt werden soll
        setShowPasswordForm(false);
        setShowPageViews(false);
      }
      return newShowAgbTable;
    });
  };

  const toggleTracking = () => {
    if (trackingEnabled) {
      localStorage.removeItem('deviceToken');
      document.cookie = 'deviceToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    } else {
      localStorage.setItem('deviceToken', 'excludeDevice');
      document.cookie = 'deviceToken=excludeDevice; path=/';
    }
    setTrackingEnabled(!trackingEnabled);
  };

  return (
<Modal show={true} onHide={handleClose} dialogClassName="slideshow-modal settings">
  <Modal.Header closeButton>
    <Modal.Title>Settings</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="tracking-control">
      <div className={`status-lamp ${trackingEnabled ? 'enabled' : 'disabled'}`}></div>
      <Button variant="info" onClick={toggleTracking}>
        {trackingEnabled ? 'Tracking aktivieren' : 'Tracking deaktivieren'}
      </Button>
    </div>
    <Button variant="info" onClick={handleTogglePageViews}>
      {showPageViews ? 'Aufrufverlauf ausblenden' : 'Aufrufverlauf anzeigen'}
    </Button>
    <Button variant="info" onClick={handleTogglePasswordForm}>
      {showPasswordForm ? 'Passwort ändern ausblenden' : 'Passwort ändern anzeigen'}
    </Button>
    <Button variant="info" onClick={handleToggleAgbTable}>
      {showAgbTable ? 'AGB-Bestätigungen ausblenden' : 'AGB-Bestätigungen anzeigen'}
    </Button>
    {renderPageViewsTable()}
    {renderPWForm()}
    {renderAgbConfirmationsTable()}
  </Modal.Body>
</Modal>
  );
};

export default Settings;
