import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import './css/notificationBar.css';

const NotificationBar = ({ message, duration, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <div className="notification-bar">
      <Alert variant="success" onClose={() => setVisible(false)} dismissible>
        {message}
        <div className="progress-bar">
          <div className="progress" style={{ animationDuration: `${duration}ms` }} />
        </div>
      </Alert>
    </div>
  );
};

export default NotificationBar;
