import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './css/modal.css';

const NewOfferModal = ({ show, onClose, onSubmit, newOfferData, setNewOfferData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOfferData({ ...newOfferData, [name]: value });
  };

  return (
    <Modal show={show} onHide={onClose} dialogClassName="new-offer-modal">
      <Modal.Header closeButton>
        <Modal.Title>Neues Angebot hinzufügen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Name" name="name" className="priceInput" value={newOfferData.name} onChange={handleInputChange} required />
          </Form.Group>
          <Form.Group controlId="formPrice">
            <Form.Label>Preis</Form.Label>
            <Form.Control type="number" placeholder="Preis" name="price" className="priceInput" value={newOfferData.price} onChange={handleInputChange} required />
          </Form.Group>
          <Form.Group controlId="formDetails">
            <Form.Label>Details</Form.Label>
            <Form.Control type="textarea" placeholder="Details" name="details" className="priceInput" value={newOfferData.details} onChange={handleInputChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Abbrechen</Button>
        <Button variant="primary" onClick={onSubmit}><FontAwesomeIcon icon={faPlus} /> Hinzufügen</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewOfferModal;
