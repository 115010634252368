import React, { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex } from '@uiw/color-convert';
import axios from 'axios';
import { UserContext } from '../MainPage';
import "./css/CreateEventModal.css";

const entwicklung = false;

const CreateEventModal = ({ show, onClose }) => {
    const { rank, user } = useContext(UserContext);
    const [eventName, setEventName] = useState("");
    const [eventDetails, setEventDetails] = useState("");
    const [eventStartDateTime, setEventStartDateTime] = useState("");
    const [eventEndDateTime, setEventEndDateTime] = useState("");
    const [hsva, setHsva] = useState({ h: 214, s: 43, v: 90, a: 1 });

    const isPastDateTime = (dateTime) => {
        return new Date(dateTime) < new Date();
    };

    const handleEventStartDateTimeChange = (e) => {
        const startDateTime = e.target.value;
        if (isPastDateTime(startDateTime)) {
            alert("Vergangene Uhrzeit kann nicht ausgewählt werden.");
            setEventStartDateTime("");
        } else {
            setEventStartDateTime(startDateTime);
            setEventEndDateTime("");
        }
    };

    const handleEventEndDateTimeChange = (e) => {
        const endDateTime = e.target.value;
        if (new Date(endDateTime) <= new Date(eventStartDateTime)) {
            alert("Endzeit kann nicht vor der Startzeit liegen.");
            setEventEndDateTime("");
        } else {
            setEventEndDateTime(endDateTime);
        }
    };

    const handleColorChange = (color) => {
        setHsva(color.hsva);
    };

    const Event_Data_Update = (event) => {
        setEventName(event.target.value);
    };

    const Event_Details_Update = (event) => {
        setEventDetails(event.target.value);
    };

    const Create_Event_Fun = async () => {
        if (eventStartDateTime && eventName && eventEndDateTime) {
            const startDateTime = eventStartDateTime;
            const endDateTime = eventEndDateTime;
            const title = eventName;
            const details = eventDetails;
            const color = hsvaToHex(hsva);

            if (!entwicklung) {
                try {
                    const response = await axios.post('https://kidsauszeit.at:5000/add_new_event', { startDateTime, endDateTime, title, details, color, user });
                    const message = response.data;

                    if (response.status === 200) {
                        alert('Event erfolgreich hinzugefügt');
                        onClose();
                    } else {
                        alert(`Fehler beim Hinzufügen des Events: ${message}`);
                    }
                } catch (error) {
                    alert('Fehler beim Hinzufügen des Events');
                    console.error('Error:', error);
                }
            } else {
                console.log(startDateTime, endDateTime, title, details, color, user);
            }
        }
    };

    return (
        <Modal show={show} onHide={onClose} dialogClassName="custom-modal-dialog">
            <Modal.Header closeButton>
                <Modal.Title>Neues Event Erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="event-form">
                    <label htmlFor="event-name">Event Name:</label>
                    <input type="text" id="event-name" value={eventName} onChange={Event_Data_Update} required />

                    <label htmlFor="event-details">Event Details:</label>
                    <input type="text" id="event-details" value={eventDetails} onChange={Event_Details_Update} />

                    <label htmlFor="start-datetime">Start (Datum und Zeit):</label>
                    <input type="datetime-local" id="start-datetime" value={eventStartDateTime} onChange={handleEventStartDateTimeChange} required />

                    <label htmlFor="end-datetime">Ende (Datum und Zeit):</label>
                    <input type="datetime-local" id="end-datetime" value={eventEndDateTime} onChange={handleEventEndDateTimeChange} required />

                    <label htmlFor="event-color">Event Farbe:</label>
                    <Wheel id="event-color" color={hsva} onChange={handleColorChange} />

                    <button className="submit-event-button" onClick={Create_Event_Fun}>Event Erstellen</button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateEventModal;
