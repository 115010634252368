// UploadModal.js

import React, { useState, useContext } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { UserContext } from '../MainPage';
import './css/modal.css';

const UploadModal = ({ NewOne, thema, currentId = null, show, onClose, onUploadSuccess }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { user } = useContext(UserContext);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedMedia(file);
  };

  const handleUpload = async () => {
    if (!user || !selectedMedia) return;

    setUploading(true);
    const formData = new FormData();
    formData.append('media', selectedMedia);
    formData.append('thema', thema);

    if (!NewOne) {
      formData.append('memberId', currentId);
    }

    const url = NewOne ? 'https://kidsauszeit.at:5000/api_new/new_upload' : 'https://kidsauszeit.at:5000/api_new/upload';

    try {
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Fehler beim Hochladen der Datei:', error);
    } finally {
      setUploading(false);
      onUploadSuccess();
      onClose();
    }
  };

  const renderPreview = () => {
    if (!selectedMedia) return null;

    const mediaType = selectedMedia.type.split('/')[0];
    const mediaURL = URL.createObjectURL(selectedMedia);

    if (mediaType === 'video') {
      return <video src={mediaURL} controls className="selected-media" />;
    }

    return <img src={mediaURL} alt="Selected" className="selected-media" />;
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Media</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="file" name="media" onChange={handleFileChange} accept="video/*,image/*" />
        {selectedMedia && (
          <div className="mt-3 preview-container">
            {renderPreview()}
            <div className="button-container">
              <Button variant="primary" onClick={handleUpload}>Hochladen</Button>
              <Button variant="danger" onClick={() => setSelectedMedia(null)}>Abbrechen</Button>
            </div>
          </div>
        )}
        {uploading && (
          <div className="spinner-container">
            <Spinner animation="border" role="status" className="spinner" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
