import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './css/modal.css';

const Impressum = ({ onClose, openContact }) => {
    return (
        <Modal show={true} onHide={onClose} dialogClassName="slideshow-modal-Imprint">
        <Modal.Header closeButton>
            <Modal.Title>Impressum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='txt'> Für Inhalt verantwortlich: Malek Bianka</div>
        <Button variant='success' onClick={openContact}> Kontakt aufnehmen</Button>
        <a className='privacy-link' href="https://kidsauszeit.at/privacy-policy.pdf" rel="noreferrer" target="_blank"><div>Privacy Policy</div></a>
        </Modal.Body>
        </Modal>
    );
};

export default Impressum;