import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { UserContext } from '../MainPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPenSquare, faPlus, faCheckToSlot, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import NewOfferModal from './NewOfferModal'; // Import der NewOfferModal-Komponente

const Price = ({ onClose }) => {
  const [price, setPrice] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newOfferData, setNewOfferData] = useState({ name: '', price: '', details: ''});
  const [showNewOfferModal, setShowNewOfferModal] = useState(false); // Zustand für die Anzeige des neuen Angebots-Modals
  const [showDetailsIndex, setShowDetailsIndex] = useState(null);
  const [editDetailsIndex, setEditDetailsIndex] = useState(null);
  const [editedDetails, setEditedDetails] = useState('');
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get('https://kidsauszeit.at:5000/api/price');
        setPrice(response.data.sort((a, b) => a.sortid - b.sortid));
      } catch (error) {
        console.error('Fehler beim Abrufen von Price', error);
      }
    };

    fetchPrice();
  }, []);

  const updateSortId = async (updatedPrice) => {
    try {
      await axios.put('https://kidsauszeit.at:5000/api/update_sortid', { prices: updatedPrice });
      setPrice(updatedPrice);
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Sortierung', error);
    }
  };

  const handleDelete = async (id) => {
    if (!user) return;

    try {
      const response = await axios.delete(`https://kidsauszeit.at:5000/api/delete_price/${id}`);
      setPrice(response.data.Price.sort((a, b) => a.sortid - b.sortid));
      alert('Angebot erfolgreich gelöscht');
    } catch (error) {
      alert('Fehler beim Löschen des Angebots');
    }
  };

  const handleTextChange = (index, field, value) => {
    if (!user) return;
    const updatedPrice = [...price];
    updatedPrice[index][field] = value;
    setPrice(updatedPrice);
  };

  const handleSaveChanges = async (index) => {
    if (!user) return;

    try {
      const response = await axios.put(`https://kidsauszeit.at:5000/api/update_price/${price[index].id}`, {
        name: price[index].name,
        price: price[index].price
      });
      
      setPrice(response.data.Price.sort((a, b) => a.sortid - b.sortid));
      alert('Angebot aktualisiert');
      setEditIndex(null); // Beenden der Bearbeitungsmodus
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Preises', error);
      alert('Fehler beim Aktualisieren des Preises');
    }
  };

  const handleDetailSaveChanges = async (index) => {
    if (!user) return;

    try {
      const detailsToSend = editedDetails.trim() !== '' ? editedDetails.replace(/\n/g, '<br>') : ''; // Leere Zeichenfolge, wenn editedDetails leer ist
      const response = await axios.put(`https://kidsauszeit.at:5000/api/update_details_price/${price[index].id}`, {
        details: detailsToSend
      });
      
      setPrice(response.data.Price.sort((a, b) => a.sortid - b.sortid));
      alert('Angebot Details aktualisiert');
      setEditDetailsIndex(null); // Beenden der Bearbeitungsmodus
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Details', error);
      alert('Fehler beim Aktualisieren der Details');
    }
  };

  const handleSubmitNewOffer = async () => {
    if (!user) return;
    setShowNewOfferModal(false);

    try {
      if (!isNaN(parseFloat(newOfferData.price)) && isFinite(newOfferData.price)) {
        const response = await axios.post('https://kidsauszeit.at:5000/api/add_price', {
          name: newOfferData.name,
          price: newOfferData.price,
          details: newOfferData.details
        });

        setPrice(response.data.Price.sort((a, b) => a.sortid - b.sortid));
        alert('Neues Angebot hinzugefügt');
        setNewOfferData({ name: '', price: '', details: '' }); // Zurücksetzen der Eingabefelder
      } else {
        alert('Bitte geben Sie einen gültigen Preis ein.');
      }
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Angebots', error);
      alert('Fehler beim Hinzufügen des Angebots');
    }
  };

  const moveOffer = (index, direction) => {
    if (!user) return;

    const newPrice = [...price];
    const targetIndex = direction === 'up' ? index - 1 : index + 1;

    if (targetIndex < 0 || targetIndex >= newPrice.length) return;

    const temp = newPrice[targetIndex].sortid;
    newPrice[targetIndex].sortid = newPrice[index].sortid;
    newPrice[index].sortid = temp;

    newPrice.sort((a, b) => a.sortid - b.sortid);
    updateSortId(newPrice);
  };

  return (
    <>
      <Modal show={!showNewOfferModal} onHide={onClose} dialogClassName="slideshow-modal">
        <Modal.Header closeButton>
          <Modal.Title>Preise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Name</th>
                <th>Preis (€)</th>
                {user && <th>Aktionen</th>}
                {user && <th>Sortierung</th>}
              </tr>
            </thead>
            <tbody>
              {price.map((offer, index) => (
                <tr key={index}>
                  <td>
                    <div 
                      contentEditable={editIndex === index} 
                      onBlur={(e) => handleTextChange(index, 'name', e.target.innerText)}
                      onClick={() => setShowDetailsIndex(showDetailsIndex === index ? null : index)}
                    >
                      {offer.name} {offer.details && <span style={{ fontStyle: 'italic' }}> (für Details klicken)</span>}
                    </div>
                    {showDetailsIndex === index && (
                      <div>
                        <div 
                          contentEditable={editDetailsIndex === index} 
                          onBlur={(e) => setEditedDetails(e.target.innerText)}
                          dangerouslySetInnerHTML={{ __html: offer.details }} 
                        ></div>
                        {user && (
                          <div>
                            {editDetailsIndex === index ? (
                              <Button variant="success" onClick={() => handleDetailSaveChanges(index)}><FontAwesomeIcon icon={faCheckToSlot} /></Button>
                            ) : (
                              <Button variant="info" onClick={() => setEditDetailsIndex(index)}><FontAwesomeIcon icon={faPenSquare} /></Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td>
                    <div 
                      contentEditable={editIndex === index} 
                      onBlur={(e) => handleTextChange(index, 'price', e.target.innerText)}
                    >
                      {offer.price}
                    </div>
                  </td>
                  {user && (
                    <>
                      <td>
                        <div>
                          {editIndex === index ? (
                            <Button variant="success" onClick={() => handleSaveChanges(index)}><FontAwesomeIcon icon={faCheckToSlot} /></Button>
                          ) : (
                            <Button variant="primary" onClick={() => setEditIndex(index)}><FontAwesomeIcon icon={faPenSquare} /></Button>
                          )}
                          <Button variant="danger" onClick={() => handleDelete(offer.id)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Button variant="secondary" onClick={() => moveOffer(index, 'up')} disabled={index === 0}><FontAwesomeIcon icon={faArrowUp} /></Button>
                          <Button variant="secondary" onClick={() => moveOffer(index, 'down')} disabled={index === price.length - 1}><FontAwesomeIcon icon={faArrowDown} /></Button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>

          {user && (
            <div className="mt-3">
              <Button variant="info" onClick={() => setShowNewOfferModal(true)}><FontAwesomeIcon icon={faPlus} /> Neues Angebot hinzufügen</Button>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* NewOfferModal Komponente */}
      <NewOfferModal
        show={showNewOfferModal}
        onClose={() => setShowNewOfferModal(false)}
        onSubmit={handleSubmitNewOffer}
        newOfferData={newOfferData}
        setNewOfferData={setNewOfferData}
      />
    </>
  );
};

export default Price;
