import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './css/map_modal.css';

const Abfahrtsplaner = ({ onClose }) => {
    const [origin, setOrigin] = useState('');
    const [directions, setDirections] = useState(null);
    const [map, setMap] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);
    const [directionsService, setDirectionsService] = useState(null);
    const [directionsPanel, setDirectionsPanel] = useState(null);
    const yourLat = 47.68092346191406; // Beispiel für Breitengrad
    const yourLng = 16.303430557250977; // Beispiel für Längengrad

    useEffect(() => {
        initMap();
        initAutocomplete();
    }, []);

    const initMap = () => {
        const newMap = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: yourLat, lng: yourLng },
            zoom: 8,
        });
        setMap(newMap);
        setDirectionsService(new window.google.maps.DirectionsService());
        setDirectionsPanel(document.getElementById("directionsPanel"));
    };

    const initAutocomplete = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('origin'), {
            fields: ['place_id', 'geometry', 'name', 'formatted_address'],
            types: ['address']
        });
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.formatted_address) {
                console.error("Autocomplete's returned place is missing required fields");
                return;
            }
            let address = place.formatted_address;
            if (!address && place.name && place.geometry && place.geometry.location) {
                address = place.name;
            }
            setOrigin(address);
        });
    };

    const geocodeAddress = (address) => {
        return new Promise((resolve, reject) => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ 'address': address }, function(results, status) {
                if (status === 'OK') {
                    const originLatLng = results[0].geometry.location;
                    resolve(originLatLng);
                } else {
                    reject('Geocode was not successful for the following reason: ' + status);
                }
            });
        });
    };

    const calcRoute = async (address) => {
        if (!map) {
            console.error('Map is not initialized');
            return;
        }
    
        if (directionsRenderer) {
            directionsRenderer.setMap(null); // Entferne vorherige Richtungen
        }
    
        // Entferne vorherige Wegbeschreibungen
        if (directionsPanel) {
            directionsPanel.innerHTML = '';
        }
    
        const dest = new window.google.maps.LatLng(yourLat, yourLng);
        
        const originLatLng = await geocodeAddress(address);
        
        if (!originLatLng) {
            console.error('Origin coordinates could not be obtained');
            return;
        }
    
        directionsService.route(
            {
                origin: originLatLng,
                destination: dest,
                travelMode: 'DRIVING'
            },
            (result, status) => {
                if (status === 'OK') {
                    setDirections(result);
                    const renderer = new window.google.maps.DirectionsRenderer();
                    renderer.setMap(map);
                    renderer.setPanel(directionsPanel);
                    renderer.setDirections(result);
                    setDirectionsRenderer(renderer);
                } else {
                    console.error('Directions request failed due to ' + status);
                }
            }
        );
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
        calcRoute(origin);
    };

    return (
        <Modal show={true} onHide={onClose} dialogClassName="slideshow-modal map">
            <Modal.Header closeButton>
                <Modal.Title>Abfahrtsplaner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} className='mapform'>
                    <Form.Group controlId="formOrigin">
                        <Form.Label>Startadresse:</Form.Label>
                        <Form.Control id="origin" type="text" placeholder="Startadresse eingeben" value={origin} onChange={(e) => setOrigin(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit">Anfahrt berechnen</Button>
                </Form>
                <div id="map" style={{ width: '100%', height: '400px', marginTop: '20px' }}></div>
                <div id="directionsPanel" className='directionpanel'></div>
            </Modal.Body>
        </Modal>
    );
};

export default Abfahrtsplaner;
