import React, { useState, useEffect, useContext } from 'react';
import Calendar from "react-calendar";
import { Modal } from 'react-bootstrap';
import CreateEventModal from './CreateEventModal';
import "react-calendar/dist/Calendar.css";
import "./css/Calender.css";
import { UserContext } from '../MainPage';

const Calender = ({ onClose }) => {
    const [ws, setWs] = useState(null);
    const { user, rank } = useContext(UserContext);
    const [selectedDate, setSelectedDate] = useState(null);
    const [events, setEvents] = useState([]);
    const [showEventForm, setShowEventForm] = useState(false);
    const [showCalendar, setShowCalendar] = useState(true);

    useEffect(() => {
        const createWebSocket = () => {
            const socket = new WebSocket('wss://kidsauszeit.at:5000');

            socket.onopen = function() {
                socket.send(JSON.stringify({ action: "events" }));
            };

            socket.onmessage = function(event) {
                const data = JSON.parse(event.data);
                switch (data.action) {
                    case "events":
                        setEvents(data.events);
                        console.log(data.events);
                        break;
                    default:
                        break;
                }
            };

            socket.onclose = () => {
                console.log('WebSocket connection closed. Reconnecting...');
                setTimeout(() => {
                    createWebSocket();
                }, 2000);
            };

            socket.onerror = () => {
                console.error('WebSocket connection failed.');
            };

            setWs(socket);
        };

        createWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);

    const getEventsForDate = (date) => {
        const dateStr = date.toISOString().split('T')[0];
        return events.filter(event => {
            const eventStart = new Date(event.startDateTime).toISOString().split('T')[0];
            const eventEnd = new Date(event.endDateTime).toISOString().split('T')[0];
            return (dateStr >= eventStart && dateStr <= eventEnd);
        });
    };

	const renderEvents = (date) => {
		const eventsForDate = getEventsForDate(date);
		if (eventsForDate.length === 0) {
			return null;
		}
	
		const sortedEvents = eventsForDate.sort((a, b) => {
			const isAMultiDay = new Date(a.startDateTime).toISOString().split('T')[0] !== new Date(a.endDateTime).toISOString().split('T')[0];
			const isBMultiDay = new Date(b.startDateTime).toISOString().split('T')[0] !== new Date(b.endDateTime).toISOString().split('T')[0];
	
			if (isAMultiDay && !isBMultiDay) {
				return -1;
			} else if (!isAMultiDay && isBMultiDay) {
				return 1;
			} else {
				return new Date(a.startDateTime) - new Date(b.startDateTime);
			}
		});
	
		const displayedEvents = sortedEvents.slice(0, 2);
		const remainingEvents = sortedEvents.length > 2 ? '...' : '';
	
		const renderMultiDayEvent = (event, date) => {
			const eventStart = new Date(event.startDateTime);
			const eventEnd = new Date(event.endDateTime);
	
			const isStart = date.toISOString().split('T')[0] === eventStart.toISOString().split('T')[0];
			const isEnd = date.toISOString().split('T')[0] === eventEnd.toISOString().split('T')[0];
	
			let className = "event-multi-day";
			if (isStart) className += " event-multi-day-start";
			if (isEnd) className += " event-multi-day-end";
	
			return (
				<div
					key={event.id}
					className={className}
					style={{ backgroundColor: event.color }}
				>
					{isStart && event.title}
				</div>
			);
		};
	
		return (
			<div className="event-container">
				{displayedEvents.map(event =>
					new Date(event.startDateTime).toISOString().split('T')[0] !== new Date(event.endDateTime).toISOString().split('T')[0]
						? renderMultiDayEvent(event, date)
						: (
							<div key={event.id} className="event" style={{ backgroundColor: event.color }}>
								{event.title}
							</div>
						)
				)}
				{remainingEvents && <div className="event-more">{remainingEvents}</div>}
			</div>
		);
	};
	

    return (
        <>
            {showCalendar && (
                <Modal show={showCalendar} onHide={onClose} dialogClassName="custom-modal-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>Kalender</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-calendar-container">
                        {rank === 'admin' && (
                            <div className="admin-controls">
                                <button className="create-event-button" onClick={() => { setShowEventForm(true); setShowCalendar(false); }}>Event Erstellen</button>
                            </div>
                        )}
                        <Calendar
                            className={`react-calendar ${rank === 'admin' ? 'with-button' : ''}`}
                            onChange={setSelectedDate}
                            value={selectedDate}
                            tileContent={({ date, view }) => view === 'month' && renderEvents(date)}
                        />
                    </Modal.Body>
                </Modal>
            )}
            <CreateEventModal
                show={showEventForm}
                onClose={() => { setShowEventForm(false); setShowCalendar(true); }}
            />
        </>
    );
};

export default Calender;
