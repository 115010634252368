import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Carousel, Button } from 'react-bootstrap';
import axios from 'axios';
import './css/modal.css';
import { UserContext } from '../MainPage';
import fallbackImagePath from './cuua.png';
import NotificationBar from './NotificationBar';
import UploadModal from './UploadModal';

const NewComp = ({ thema, headText, onClose }) => {
  const [daten, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [editText, setEditText] = useState(false);
  const [newOne, setNewOne] = useState(false);
  const [error, setError] = useState(false);
  const [newText, setNewText] = useState('');
  const [currentText, setCurrentText] = useState('');
  const [notification, setNotification] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const videoRef = useRef(null);
  const { user } = useContext(UserContext);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://kidsauszeit.at:5000/api_new`, {
        params: { thema }
      });
      if (response.data.length > 0) {
        setData(response.data);
        setCurrentText(response.data[0].text);
      } else {
        throw new Error('No data available');
      }
    } catch (error) {
      console.error(`Fehler beim Abrufen des ${thema}:`, error);
      setError(true);
      setData([{ media: 'cuua.png', text: 'Fehler beim Laden der Daten' }]);
      setCurrentText('Fehler beim Laden der Daten');
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    fetchData();
  }, [thema]);

  const reloadWithDelay = () => {
    setIsLoading(true);
    fetchData();
  };

  const handleSelect = (selectedIndex, e) => {
    setCurrentIndex(selectedIndex);
    setEditText(false);
    setCurrentText(daten[selectedIndex]?.text || '');
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleTextChange = async () => {
    if (!user) return;

    const currentId = daten[currentIndex]?.id;

    try {
      await axios.put(`https://kidsauszeit.at:5000/api_new/textchange`, {
        text: newText,
        memberId: currentId
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setEditText(false);
      setNewText('');
      setCurrentText(newText);
      setNotification({ message: 'Text erfolgreich aktualisiert', duration: 1500 });
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Textes:', error);
      setNotification({ message: 'Fehler beim Aktualisieren des Textes', duration: 1500 });
    }
  };

  const handleDelete = async () => {
    if (!user) return;

    setIsLoading(true);

    const mediaName = daten[currentIndex]?.media;

    try {
      const response = await axios.delete(`https://kidsauszeit.at:5000/api_new/delete`, {
        params: {
          mediaName: mediaName,
          thema: thema
        }
      });

      setNotification({ message: 'Medien erfolgreich gelöscht', duration: 1500 });
      setData(response.data.Daten);
      setCurrentIndex(prevIndex => prevIndex === 0 ? 0 : prevIndex - 1);
    } catch (error) {
      setNotification({ message: 'Fehler beim Löschen der Medien', duration: 1500 });
    } finally {
      reloadWithDelay();
    }
  };

  const handleNewUploadClick = () => {
    setNewOne(true);
    setShowUploadModal(true);
  };

  const handleChangeUploadClick = () => {
    setNewOne(false);
    setShowUploadModal(true);
  };

  const handleUploadSuccess = () => {
    setCurrentIndex(0);
    setShowUploadModal(false);
    setNotification({ message: 'Medien erfolgreich hochgeladen', duration: 1500 });
    reloadWithDelay();
  };

  const handleUploadModalClose = () => {
    setShowUploadModal(false);
    reloadWithDelay();
  };

  const observeVideo = (videoElement) => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      },
      {
        threshold: 0.25
      }
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  };

  useEffect(() => {
    if (videoRef.current) {
      return observeVideo(videoRef.current);
    }
  }, [currentIndex]);

  return (
    <>
      <Modal show={!showUploadModal} onHide={onClose} dialogClassName="slideshow-modal">
        <Modal.Header closeButton>
          <Modal.Title>{headText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && (
            <div className="loading-placeholder">
              Laden...
            </div>
          )}

          {!isLoading && daten.length > 0 ? (
            <Carousel activeIndex={currentIndex} onSelect={handleSelect} interval={null}>
              {daten.map((member, index) => (
                <Carousel.Item key={index}>
                  {member.media.endsWith('.mp4') ? (
                    <video ref={index === currentIndex ? videoRef : null} controls className="d-block w-100">
                      <source src={`/uploads/${member.media}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      className="d-block w-100 fit-image"
                      src={`/uploads/${member.media}`}
                      alt={`Slide ${index}`}
                    />
                  )}
                  {currentText && (
                    <Carousel.Caption>
                      <h3
                        contentEditable={editText}
                        dangerouslySetInnerHTML={{ __html: currentText }}
                        onBlur={(e) => setNewText(e.target.innerText)}
                      />
                    </Carousel.Caption>
                  )}
                </Carousel.Item>
              ))}
            </Carousel>
          ) : !isLoading && (
            <img
              src={fallbackImagePath}
              alt="No data available"
              className="d-block w-100 fit-image"
            />
          )}
          {user && !isLoading && !error && (
            <div className="mt-3 button-container">
              <Button variant="primary" onClick={handleNewUploadClick}>Neue Medien hochladen</Button>
              <Button variant="primary" onClick={handleChangeUploadClick}>Medien ändern</Button>
              {!editText && <Button variant="primary" onClick={() => setEditText(true)}>Text bearbeiten</Button>}
              {editText && <Button variant="primary" onClick={handleTextChange}>Speichern</Button>}
              <Button variant="danger" onClick={handleDelete}>Medien löschen</Button>
            </div>
          )}
          {notification && (
            <NotificationBar
              message={notification.message}
              duration={notification.duration}
              onClose={() => setNotification(null)}
            />
          )}
        </Modal.Body>
      </Modal>

      <UploadModal
        NewOne={newOne}
        thema={thema}
        currentId={daten[currentIndex]?.id}
        show={showUploadModal}
        onClose={handleUploadModalClose}
        onUploadSuccess={handleUploadSuccess}
      />
    </>
  );
};

export default NewComp;
