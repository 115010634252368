import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';
import './style.css';

const Footer = ({ openImp }) => {
  return (
    <footer className="bg-dark text-light py-3 border-top footer">
      <Container>
        <Row className="align-items-center">
          <Col md={4}  className="d-flex align-items-center">
            <span className="mb-3 mb-md-0 text-light">© 2022 - {new Date().getFullYear()} S`Reitackerl. Alle Rechte vorbehalten.</span>
          </Col>
          <Col md={4} className="d-flex align-items-center"></Col>
          <Col md={4} className="d-flex justify-content-end">
          <ul className="nav list-unstyled d-flex">
              <li className="ms-3 whatsapp"><a className="text-light" href="https://api.whatsapp.com/send?phone=436804052396&text=%F0%9F%98%80%20Hey%20ich%20komme%20von%20deiner%20Website"><FontAwesomeIcon icon={faWhatsapp} /><use href="#whatsapp"></use></a></li>
              <li className="ms-3 insta"><a className="text-light" href="https://www.instagram.com/kids_auszeit_mit_pferden/"><FontAwesomeIcon icon={faInstagram} /><use href="#instagram"></use></a></li>
              <li className="ms-3 fb"><a className="text-light" href="https://www.facebook.com/Kids-Auszeit-mit-Pferden-106439927824132"><FontAwesomeIcon icon={faFacebook} /><use href="#facebook"></use></a></li>
          </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;